import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import '../assets/styles/pages/actions-list.scss';

const ActionsPage = ({ data }) => (
  <Layout showTitleRow="What we’re doing">
    <div className="container">
      {
        data.allDatoCmsAction.edges.map(({ node: action }) => (
          <Link to={`/actions/${action.slug}`} className="action-tile two-quarters no-margin-x" key={action.id}>
            <strong>
              {action.actionType}
              <br />
            </strong>
            {action.name && (
              <strong>
                {action.name}
                <br />
              </strong>
            )}
            <strong>
              <em>
                {action.title}
              </em>
              <br />
              <br />
            </strong>
            {action.featured.fluid && action.featured.filename.split('.').pop() !== 'svg' ? (
              <Img fluid={{ ...action.featured.fluid, aspectRatio: 16 / 9 }} />
            ) : (
              <img src={action.featured.url} alt={action.title} width="100%" />
            )}
          </Link>
        ))
      }
    </div>
  </Layout>
);

export const query = graphql`
  query ActionsQuery {
    allDatoCmsAction(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          id
          slug
          actionType
          name
          title
          date
          order
          featured {
            fluid(maxWidth: 1540) {
              ...GatsbyDatoCmsSizes
            }
            url
            filename
          }
        }
      }
    }
  }
`;

ActionsPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default ActionsPage;
